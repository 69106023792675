import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";
import { ButtonContent } from "../global/boton/ButtonContent";
import { FaMapMarkerAlt } from "react-icons/fa";

function HeroSection({ Img1, Img2, Img3, Img4 }) {
  const { rpdata } = useContext(GlobalDataContext);

  const bgslide = [
    Img1,
    Img2,
    Img3,
    Img4
  ]
  return (
    <>
      <div className="pic-wrapper">

        {
          bgslide.map((img, index) => {
            return (
              <figure
                key={index}
                className={`pic-${index + 1}`}
                style={{ background: `url("${img}")` }}
              ></figure>
            )
          })
        }

        <div className="z-10 relative w-4/5 mx-auto md:h-[100vh] h-[158vh]">
          <div className="w-full h-full pb-[8%] md:pb-[2%] md:pr-[40%] pr-0 flex flex-col justify-end items-center md:items-start">
            <h1 className="text-white md:text-[3.0em] text-[2.0em] text-center md:text-start">
              {rpdata?.dbSlogan?.[0].slogan}
            </h1>
            <p className="text-white text-center md:text-start">{rpdata?.dbValues?.[0].description}</p>
            <br />
            <div className="flex flex-row flex-wrap justify-center items-center">
              {
                rpdata?.dbPrincipal?.location?.map((item, index) => {
                  return (
                    <>
                      <FaMapMarkerAlt className="text-white " />
                      <p key={index} className="text-white text-center md:text-start">{item.address}</p>
                    </>
                  )
                })
              }
            </div>
            <div className="flex justify-center md:block">
              <ButtonContent />
            </div>
          </div>
          <div className="w-1/2"></div>
        </div>
      </div>
    </>
  );
}

export default HeroSection;
